<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="店铺id 外键shop_shop.id" prop="shopId">
        <a-input v-model="form.shopId" placeholder="请输入店铺id 外键shop_shop.id"/>
      </a-form-model-item>
      <a-form-model-item label="剧本id 外键script_script.id" prop="scriptId">
        <a-input v-model="form.scriptId" placeholder="请输入剧本id 外键script_script.id"/>
      </a-form-model-item>
      <a-form-model-item label="是否收录 1:是,0:否" prop="isInclude">
        <a-input v-model="form.isInclude" placeholder="请输入是否收录 1:是,0:否"/>
      </a-form-model-item>
      <a-form-model-item label="是否上架 1:是,0:否" prop="isOnSale">
        <a-input v-model="form.isOnSale" placeholder="请输入是否上架 1:是,0:否"/>
      </a-form-model-item>
      <a-form-model-item label="价格 元/人" prop="price">
      </a-form-model-item>
      <a-form-model-item label="是否热推 1:是,0:否" prop="isHot">
        <a-input v-model="form.isHot" placeholder="请输入是否热推 1:是,0:否"/>
      </a-form-model-item>
      <a-form-model-item label="是否上新 1:是,0:否" prop="isNew">
        <a-input v-model="form.isNew" placeholder="请输入是否上新 1:是,0:否"/>
      </a-form-model-item>
      <a-form-model-item label="是否预热 1:是,0:否" prop="isPre">
        <a-input v-model="form.isPre" placeholder="请输入是否预热 1:是,0:否"/>
      </a-form-model-item>
      <a-form-model-item label="新本预热信息 " prop="preInfo" >
      </a-form-model-item>
      <a-form-model-item label="是否镇店 1:是,0:否" prop="isTreasure">
        <a-input v-model="form.isTreasure" placeholder="请输入是否镇店 1:是,0:否"/>
      </a-form-model-item>
      <a-form-model-item label="镇店信息 json" prop="treasureInfo" >
      </a-form-model-item>
      <a-form-model-item label="逻辑删除标记，1：删除，0：未删除，默认0" prop="isDeleted" v-if="formType === 1">
        <a-input v-model="form.isDeleted" placeholder="请输入逻辑删除标记，1：删除，0：未删除，默认0"/>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark">
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="乐观锁" prop="version" v-if="formType === 1">
        <a-input v-model="form.version" placeholder="请输入乐观锁"/>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {getShopScript, addShopScript, updateShopScript} from '@/api/shop/shopScript'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        shopId: null,

        scriptId: null,

        isInclude: null,

        isOnSale: null,

        price: null,

        isHot: null,

        isNew: null,

        isPre: null,

        preInfo: null,

        isTreasure: null,

        treasureInfo: null,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,

        version: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        shopId: [
          {required: true, message: '店铺id 外键shop_shop.id不能为空', trigger: 'blur'}
        ],

        scriptId: [
          {required: true, message: '剧本id 外键script_script.id不能为空', trigger: 'blur'}
        ],

        isInclude: [
          {required: true, message: '是否收录 1:是,0:否不能为空', trigger: 'blur'}
        ],

        isOnSale: [
          {required: true, message: '是否上架 1:是,0:否不能为空', trigger: 'blur'}
        ],

        isHot: [
          {required: true, message: '是否热推 1:是,0:否不能为空', trigger: 'blur'}
        ],

        isNew: [
          {required: true, message: '是否上新 1:是,0:否不能为空', trigger: 'blur'}
        ],

        isPre: [
          {required: true, message: '是否预热 1:是,0:否不能为空', trigger: 'blur'}
        ],

        isTreasure: [
          {required: true, message: '是否镇店 1:是,0:否不能为空', trigger: 'blur'}
        ],

        isDeleted: [
          {required: true, message: '逻辑删除标记，1：删除，0：未删除，默认0不能为空', trigger: 'blur'}
        ],

        version: [
          {required: true, message: '乐观锁不能为空', trigger: 'blur'}
        ]

      }
    }
  },
  filters: {},
  created() {
  },
  computed: {},
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,

        shopId: null,

        scriptId: null,

        isInclude: null,

        isOnSale: null,

        price: null,

        isHot: null,

        isNew: null,

        isPre: null,

        preInfo: null,

        isTreasure: null,

        treasureInfo: null,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,

        version: null

      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getShopScript(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateShopScript(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addShopScript(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
